var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"photoTrapResult"},[_c('h4',{staticClass:"m-0 mt-4"},[_c('span',{staticClass:"mr-2"},[_vm._v(_vm._s(_vm.$t('results')))]),(_vm.items)?_c('small',[_vm._v("("+_vm._s(_vm.items.length)+")")]):_vm._e()]),_c('hr',{staticClass:"mb-4 m-0"}),_c('Grid',{ref:_vm.kgm_ref,style:({height: 'auto'}),attrs:{"data-items":_vm.kgm_computedGridItems(_vm.items),"columns":_vm.kgm_responsiveColumns(),"filterable":false,"filter":_vm.kgm_filter,"pageable":_vm.kgm_pagable,"page-size":_vm.kgm_take,"skip":_vm.kgm_skip,"take":_vm.kgm_take,"total":_vm.kgm_allGridItems(_vm.items),"sortable":{
      allowUnsort: _vm.kgm_allowUnsort,
      mode: _vm.kgm_sortMode
    },"sort":_vm.kgm_sort,"selected-field":"selected"},on:{"rowclick":_vm.kgm_gridOnRowClick,"filterchange":_vm.kgm_gridFilterChange,"pagechange":_vm.kgm_gridPageChange,"sortchange":_vm.kgm_gridSortChange},scopedSlots:_vm._u([{key:"dateTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[_c('span',{directives:[{name:"tooltip",rawName:"v-tooltip",value:(_vm.dateTime_dateTime(_vm.kgm_getNestedValue(props.field, props.dataItem))),expression:"dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))"}]},[_vm._v(_vm._s(_vm.dateTime_fromNow(_vm.kgm_getNestedValue(props.field, props.dataItem))))])])]}},{key:"previewImageTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem)?_c('PhotoTrapImage',{attrs:{"token":props.dataItem.previewImageFileToken},on:{"buttonClick":function($event){return _vm.openModal(props.dataItem.imageFileToken)}}}):_vm._e()],1)]}},{key:"debugImageTemplate",fn:function(ref){
    var props = ref.props;
return [_c('td',{class:props.className},[(props.dataItem && _vm.loadDebugImages)?_c('PhotoTrapImage',{attrs:{"token":props.dataItem.debugImageFileToken},on:{"buttonClick":function($event){return _vm.openModal(props.dataItem.debugImageFileToken)}}}):_vm._e()],1)]}}])}),_c('SweetModal',{ref:"defaultPhotoTrapModal",staticClass:"overflowHidden",attrs:{"id":"DefaultPhotoTrapResult","title":"Image"},on:{"close":_vm.closeModal}},[(!_vm.modalImageUrl)?_c('LoadingPlaceholder'):_c('img',{staticClass:"img-fluid",attrs:{"id":"img","src":_vm.modalImageUrl}})],1),_c('SweetModal',{ref:"debugPhotoTrapModal",staticClass:"overflowHidden",attrs:{"id":_vm.debugModalId,"title":"Image"},on:{"close":_vm.closeModal}},[(!_vm.modalImageUrl)?_c('LoadingPlaceholder'):_c('img',{staticClass:"img-fluid",attrs:{"id":"img","src":_vm.modalImageUrl}})],1)],1)}
var staticRenderFns = []

export { render, staticRenderFns }