<template>
  <div
    class="photoTrapResult"
  >
    <h4 class="m-0 mt-4">
      <span class="mr-2">{{ $t('results') }}</span>
      <small v-if="items">({{ items.length }})</small>
    </h4>
    <hr class="mb-4 m-0">
    <Grid
      :ref="kgm_ref"
      :style="{height: 'auto'}"
      :data-items="kgm_computedGridItems(items)"
      :columns="kgm_responsiveColumns()"
      :filterable="false"
      :filter="kgm_filter"
      :pageable="kgm_pagable"
      :page-size="kgm_take"
      :skip="kgm_skip"
      :take="kgm_take"
      :total="kgm_allGridItems(items)"
      :sortable="{
        allowUnsort: kgm_allowUnsort,
        mode: kgm_sortMode
      }"
      :sort="kgm_sort"
      selected-field="selected"
      @rowclick="kgm_gridOnRowClick"
      @filterchange="kgm_gridFilterChange"
      @pagechange="kgm_gridPageChange"
      @sortchange="kgm_gridSortChange"
    >
      <template
        slot="dateTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <span v-tooltip="dateTime_dateTime(kgm_getNestedValue(props.field, props.dataItem))">{{ dateTime_fromNow(kgm_getNestedValue(props.field, props.dataItem)) }}</span>
        </td>
      </template>
      <template
        slot="previewImageTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <PhotoTrapImage
            v-if="props.dataItem"
            :token="props.dataItem.previewImageFileToken"
            @buttonClick="openModal(props.dataItem.imageFileToken)"
          />
        </td>
      </template>
      <template
        slot="debugImageTemplate"
        slot-scope="{props}"
      >
        <td :class="props.className">
          <PhotoTrapImage
            v-if="props.dataItem && loadDebugImages"
            :token="props.dataItem.debugImageFileToken"
            @buttonClick="openModal(props.dataItem.debugImageFileToken)"
          />
        </td>
      </template>
    </Grid>

    <SweetModal
      id="DefaultPhotoTrapResult"
      ref="defaultPhotoTrapModal"
      title="Image"
      class="overflowHidden"
      @close="closeModal"
    >
      <LoadingPlaceholder v-if="!modalImageUrl" />
      <img
        v-else
        id="img"
        :src="modalImageUrl"
        class="img-fluid"
      >
    </SweetModal>

    <SweetModal
      :id="debugModalId"
      ref="debugPhotoTrapModal"
      title="Image"
      class="overflowHidden"
      @close="closeModal"
    >
      <LoadingPlaceholder v-if="!modalImageUrl" />
      <img
        v-else
        id="img"
        :src="modalImageUrl"
        class="img-fluid"
      >
    </SweetModal>
  </div>
</template>

<script>
import { kendoGridMixin } from '@/mixins/kendoGridMixin.js';
import { SweetModal } from 'sweet-modal-vue';
import { dateTimeMixin } from '@/mixins/dateTimeMixin.js';

export default {
  name: "PhotoTrapResult",
  components: {
    SweetModal,
    PhotoTrapImage: () => import('@/components/PhotoTrap/PhotoTrapImage.vue')
  },
  mixins: [
    dateTimeMixin,
    kendoGridMixin
  ],
  props: {
    items: {
      type: Array,
      required: false,
      default () {
        return null;
      }
    },
    loadDebugImages: {
      type: Boolean,
      required: true
    }
  },
  data () {
    return {
      loading: false,
      defaultModalId: 'DefaultPhotoTrapResult',
      debugModalId: 'DebugPhotoTrapResult',
      modalImageUrl: null,
      kgm_sort: [
        {
          field: "createDate",
          dir: "desc",
          cell: 'dateTemplate',
        }
      ],
      kgm_columns: [
        {
          field: 'previewImageFileToken',
          filterable: false,
          title: 'Preview',
          cell: 'previewImageTemplate'
        },
        {
          field: 'createDate',
          filterable: true,
          filter: 'date',
          title: 'Date',
          cell: 'dateTemplate',
          width: '160px'
        },
        {
          field: 'debugImageFileToken',
          filterable: false,
          title: 'Debug-Image',
          cell: 'debugImageTemplate'
        }
      ]
    }
  },
  methods: {
    getStandardFile (token) {
      this.axios.get(`/PhotoTrap/GetFile?fileToken=${ token }`)
        .then((response) => {
          if (response == null) {
            return;
          }
          if (response.data == null) {
            return;
          }
          if (response.data.base64Data == null) {
            return;
          }
          this.modalImageUrl = 'data:image/jpg;base64,' + response.data.base64Data;
        });
    },
    // modal methods
    openModal (token) {
      if (token) {
        this.modalImageUrl = this.getStandardFile(token);
      }
      this.$refs.defaultPhotoTrapModal.open();
    },
    closeModal () {
      this.modalImageUrl = null;
    }
  }
}
</script>

<style scoped>
.badge.badge-secondary {
  font-size: 1.25em;
}
</style>
